export const isNumberOrFloatingNumber = (value: string) => {
  return /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(value);
};

//eslint-disable-next-line
export const PHONE_NUMBER: RegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

//eslint-disable-next-line
export const COMMA_SEPARATED_NUMBERS: RegExp = /^[1-9][0-9]*(,[0-9]+)*?$/;

//eslint-disable-next-line
export const POSITIVE_NUMBER: RegExp = /^\d+$/;
