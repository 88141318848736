export const COUNTRIES_LIST = [
  { value: 'saudi arabia', label: 'Saudi Arabia' },
  { value: 'united arab emirates', label: 'United Arab Emirates' },
  { value: 'kuwait', label: 'Kuwait' },
  { value: 'oman', label: 'Oman' },
  { value: 'bahrain', label: 'Bahrain' },
  { value: 'egypt', label: 'Egypt' },
  { value: 'jordan', label: 'Jordan' },
  { value: 'lebanon', label: 'Lebanon' },
  { value: 'tunisia', label: 'Tunisia' },
  { value: 'morocco', label: 'Morocco' },
  { value: 'libyan arab jamahiriya', label: 'Libyan Arab Jamahiriya' },
  { value: 'afghanistan', label: 'Afghanistan' },
  { value: 'åland islands', label: 'Åland Islands' },
  { value: 'albania', label: 'Albania' },
  { value: 'algeria', label: 'Algeria' },
  { value: 'american samoa', label: 'American Samoa' },
  { value: 'andorra', label: 'AndorrA' },
  { value: 'angola', label: 'Angola' },
  { value: 'anguilla', label: 'Anguilla' },
  { value: 'antarctica', label: 'Antarctica' },
  { value: 'antigua and barbuda', label: 'Antigua and Barbuda' },
  { value: 'argentina', label: 'Argentina' },
  { value: 'armenia', label: 'Armenia' },
  { value: 'aruba', label: 'Aruba' },
  { value: 'australia', label: 'Australia' },
  { value: 'austria', label: 'Austria' },
  { value: 'azerbaijan', label: 'Azerbaijan' },
  { value: 'bahamas', label: 'Bahamas' },
  { value: 'bangladesh', label: 'Bangladesh' },
  { value: 'barbados', label: 'Barbados' },
  { value: 'belarus', label: 'Belarus' },
  { value: 'belgium', label: 'Belgium' },
  { value: 'belize', label: 'Belize' },
  { value: 'benin', label: 'Benin' },
  { value: 'bermuda', label: 'Bermuda' },
  { value: 'bhutan', label: 'Bhutan' },
  { value: 'bolivia', label: 'Bolivia' },
  { value: 'bosnia and herzegovina', label: 'Bosnia and Herzegovina' },
  { value: 'botswana', label: 'Botswana' },
  { value: 'bouvet island', label: 'Bouvet Island' },
  { value: 'brazil', label: 'Brazil' },
  { value: 'british indian ocean territory', label: 'British Indian Ocean Territory' },
  { value: 'brunei darussalam', label: 'Brunei Darussalam' },
  { value: 'bulgaria', label: 'Bulgaria' },
  { value: 'burkina faso', label: 'Burkina Faso' },
  { value: 'burundi', label: 'Burundi' },
  { value: 'cambodia', label: 'Cambodia' },
  { value: 'cameroon', label: 'Cameroon' },
  { value: 'canada', label: 'Canada' },
  { value: 'cape verde', label: 'Cape Verde' },
  { value: 'cayman islands', label: 'Cayman Islands' },
  { value: 'central african republic', label: 'Central African Republic' },
  { value: 'chad', label: 'Chad' },
  { value: 'chile', label: 'Chile' },
  { value: 'china', label: 'China' },
  { value: 'christmas island', label: 'Christmas Island' },
  { value: 'cocos (keeling) islands', label: 'Cocos (Keeling) Islands' },
  { value: 'colombia', label: 'Colombia' },
  { value: 'comoros', label: 'Comoros' },
  { value: 'congo', label: 'Congo' },
  { value: 'congo, the democratic republic of the', label: 'Congo, The Democratic Republic of the' },
  { value: 'cook islands', label: 'Cook Islands' },
  { value: 'costa rica', label: 'Costa Rica' },
  { value: "cote d'ivoire", label: "Cote D'Ivoire" },
  { value: 'croatia', label: 'Croatia' },
  { value: 'cuba', label: 'Cuba' },
  { value: 'cyprus', label: 'Cyprus' },
  { value: 'czech republic', label: 'Czech Republic' },
  { value: 'denmark', label: 'Denmark' },
  { value: 'djibouti', label: 'Djibouti' },
  { value: 'dominica', label: 'Dominica' },
  { value: 'dominican republic', label: 'Dominican Republic' },
  { value: 'ecuador', label: 'Ecuador' },
  { value: 'el salvador', label: 'El Salvador' },
  { value: 'equatorial guinea', label: 'Equatorial Guinea' },
  { value: 'eritrea', label: 'Eritrea' },
  { value: 'estonia', label: 'Estonia' },
  { value: 'ethiopia', label: 'Ethiopia' },
  { value: 'falkland islands (malvinas)', label: 'Falkland Islands (Malvinas)' },
  { value: 'faroe islands', label: 'Faroe Islands' },
  { value: 'fiji', label: 'Fiji' },
  { value: 'finland', label: 'Finland' },
  { value: 'france', label: 'France' },
  { value: 'french guiana', label: 'French Guiana' },
  { value: 'french polynesia', label: 'French Polynesia' },
  { value: 'french southern territories', label: 'French Southern Territories' },
  { value: 'gabon', label: 'Gabon' },
  { value: 'gambia', label: 'Gambia' },
  { value: 'georgia', label: 'Georgia' },
  { value: 'germany', label: 'Germany' },
  { value: 'ghana', label: 'Ghana' },
  { value: 'gibraltar', label: 'Gibraltar' },
  { value: 'greece', label: 'Greece' },
  { value: 'greenland', label: 'Greenland' },
  { value: 'grenada', label: 'Grenada' },
  { value: 'guadeloupe', label: 'Guadeloupe' },
  { value: 'guam', label: 'Guam' },
  { value: 'guatemala', label: 'Guatemala' },
  { value: 'guernsey', label: 'Guernsey' },
  { value: 'guinea', label: 'Guinea' },
  { value: 'guinea-bissau', label: 'Guinea-Bissau' },
  { value: 'guyana', label: 'Guyana' },
  { value: 'haiti', label: 'Haiti' },
  { value: 'heard island and mcdonald islands', label: 'Heard Island and Mcdonald Islands' },
  { value: 'holy see (vatican city state)', label: 'Holy See (Vatican City State)' },
  { value: 'honduras', label: 'Honduras' },
  { value: 'hong kong', label: 'Hong Kong' },
  { value: 'hungary', label: 'Hungary' },
  { value: 'iceland', label: 'Iceland' },
  { value: 'india', label: 'India' },
  { value: 'indonesia', label: 'Indonesia' },
  { value: 'iran, islamic republic of', label: 'Iran, Islamic Republic Of' },
  { value: 'iraq', label: 'Iraq' },
  { value: 'ireland', label: 'Ireland' },
  { value: 'isle of man', label: 'Isle of Man' },
  { value: 'palestine', label: 'Palestine' },
  { value: 'italy', label: 'Italy' },
  { value: 'jamaica', label: 'Jamaica' },
  { value: 'japan', label: 'Japan' },
  { value: 'jersey', label: 'Jersey' },
  { value: 'kazakhstan', label: 'Kazakhstan' },
  { value: 'kenya', label: 'Kenya' },
  { value: 'kiribati', label: 'Kiribati' },
  { value: "korea, democratic people's republic of", label: "Korea, Democratic People'S Republic of" },
  { value: 'korea, republic of', label: 'Korea, Republic of' },
  { value: 'kyrgyzstan', label: 'Kyrgyzstan' },
  { value: "lao people's democratic republic", label: "Lao People'S Democratic Republic" },
  { value: 'latvia', label: 'Latvia' },
  { value: 'lesotho', label: 'Lesotho' },
  { value: 'liberia', label: 'Liberia' },
  { value: 'liechtenstein', label: 'Liechtenstein' },
  { value: 'lithuania', label: 'Lithuania' },
  { value: 'luxembourg', label: 'Luxembourg' },
  { value: 'macao', label: 'Macao' },
  { value: 'macedonia, the former yugoslav republic of', label: 'Macedonia, The Former Yugoslav Republic of' },
  { value: 'madagascar', label: 'Madagascar' },
  { value: 'malawi', label: 'Malawi' },
  { value: 'malaysia', label: 'Malaysia' },
  { value: 'maldives', label: 'Maldives' },
  { value: 'mali', label: 'Mali' },
  { value: 'malta', label: 'Malta' },
  { value: 'marshall islands', label: 'Marshall Islands' },
  { value: 'martinique', label: 'Martinique' },
  { value: 'mauritania', label: 'Mauritania' },
  { value: 'mauritius', label: 'Mauritius' },
  { value: 'mayotte', label: 'Mayotte' },
  { value: 'mexico', label: 'Mexico' },
  { value: 'micronesia, federated states of', label: 'Micronesia, Federated States of' },
  { value: 'moldova, republic of', label: 'Moldova, Republic of' },
  { value: 'monaco', label: 'Monaco' },
  { value: 'mongolia', label: 'Mongolia' },
  { value: 'montserrat', label: 'Montserrat' },
  { value: 'mozambique', label: 'Mozambique' },
  { value: 'myanmar', label: 'Myanmar' },
  { value: 'namibia', label: 'Namibia' },
  { value: 'nauru', label: 'Nauru' },
  { value: 'nepal', label: 'Nepal' },
  { value: 'netherlands', label: 'Netherlands' },
  { value: 'netherlands antilles', label: 'Netherlands Antilles' },
  { value: 'new caledonia', label: 'New Caledonia' },
  { value: 'new zealand', label: 'New Zealand' },
  { value: 'nicaragua', label: 'Nicaragua' },
  { value: 'niger', label: 'Niger' },
  { value: 'nigeria', label: 'Nigeria' },
  { value: 'niue', label: 'Niue' },
  { value: 'norfolk island', label: 'Norfolk Island' },
  { value: 'northern mariana islands', label: 'Northern Mariana Islands' },
  { value: 'norway', label: 'Norway' },
  { value: 'pakistan', label: 'Pakistan' },
  { value: 'palau', label: 'Palau' },
  { value: 'panama', label: 'Panama' },
  { value: 'papua new guinea', label: 'Papua New Guinea' },
  { value: 'paraguay', label: 'Paraguay' },
  { value: 'peru', label: 'Peru' },
  { value: 'philippines', label: 'Philippines' },
  { value: 'pitcairn', label: 'Pitcairn' },
  { value: 'poland', label: 'Poland' },
  { value: 'portugal', label: 'Portugal' },
  { value: 'puerto rico', label: 'Puerto Rico' },
  { value: 'qatar', label: 'Qatar' },
  { value: 'reunion', label: 'Reunion' },
  { value: 'romania', label: 'Romania' },
  { value: 'russian federation', label: 'Russian Federation' },
  { value: 'rwanda', label: 'RWANDA' },
  { value: 'saint helena', label: 'Saint Helena' },
  { value: 'saint kitts and nevis', label: 'Saint Kitts and Nevis' },
  { value: 'saint lucia', label: 'Saint Lucia' },
  { value: 'saint pierre and miquelon', label: 'Saint Pierre and Miquelon' },
  { value: 'saint vincent and the grenadines', label: 'Saint Vincent and the Grenadines' },
  { value: 'samoa', label: 'Samoa' },
  { value: 'san marino', label: 'San Marino' },
  { value: 'sao tome and principe', label: 'Sao Tome and Principe' },
  { value: 'senegal', label: 'Senegal' },
  { value: 'serbia and montenegro', label: 'Serbia and Montenegro' },
  { value: 'seychelles', label: 'Seychelles' },
  { value: 'sierra leone', label: 'Sierra Leone' },
  { value: 'singapore', label: 'Singapore' },
  { value: 'slovakia', label: 'Slovakia' },
  { value: 'slovenia', label: 'Slovenia' },
  { value: 'solomon islands', label: 'Solomon Islands' },
  { value: 'somalia', label: 'Somalia' },
  { value: 'south africa', label: 'South Africa' },
  { value: 'south georgia and the south sandwich islands', label: 'South Georgia and the South Sandwich Islands' },
  { value: 'spain', label: 'Spain' },
  { value: 'sri lanka', label: 'Sri Lanka' },
  { value: 'sudan', label: 'Sudan' },
  { value: 'surilabel', label: 'Surilabel' },
  { value: 'svalbard and jan mayen', label: 'Svalbard and Jan Mayen' },
  { value: 'swaziland', label: 'Swaziland' },
  { value: 'sweden', label: 'Sweden' },
  { value: 'switzerland', label: 'Switzerland' },
  { value: 'syrian arab republic', label: 'Syrian Arab Republic' },
  { value: 'taiwan, province of china', label: 'Taiwan, Province of China' },
  { value: 'tajikistan', label: 'Tajikistan' },
  { value: 'tanzania, united republic of', label: 'Tanzania, United Republic of' },
  { value: 'thailand', label: 'Thailand' },
  { value: 'timor-leste', label: 'Timor-Leste' },
  { value: 'togo', label: 'Togo' },
  { value: 'tokelau', label: 'Tokelau' },
  { value: 'tonga', label: 'Tonga' },
  { value: 'trinidad and tobago', label: 'Trinidad and Tobago' },
  { value: 'turkey', label: 'Turkey' },
  { value: 'turkmenistan', label: 'Turkmenistan' },
  { value: 'turks and caicos islands', label: 'Turks and Caicos Islands' },
  { value: 'tuvalu', label: 'Tuvalu' },
  { value: 'uganda', label: 'Uganda' },
  { value: 'ukraine', label: 'Ukraine' },
  { value: 'united kingdom', label: 'United Kingdom' },
  { value: 'united states', label: 'United States' },
  { value: 'united states minor outlying islands', label: 'United States Minor Outlying Islands' },
  { value: 'uruguay', label: 'Uruguay' },
  { value: 'uzbekistan', label: 'Uzbekistan' },
  { value: 'vanuatu', label: 'Vanuatu' },
  { value: 'venezuela', label: 'Venezuela' },
  { value: 'viet nam', label: 'Viet Nam' },
  { value: 'virgin islands, british', label: 'Virgin Islands, British' },
  { value: 'virgin islands, u.s.', label: 'Virgin Islands, U.S.' },
  { value: 'wallis and futuna', label: 'Wallis and Futuna' },
  { value: 'western sahara', label: 'Western Sahara' },
  { value: 'yemen', label: 'Yemen' },
  { value: 'zambia', label: 'Zambia' },
  { value: 'zimbabwe', label: 'Zimbabwe' },
];
